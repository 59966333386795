import axios from 'axios';
import _ from 'lodash';
import environment from 'environment';

const handleLogOut = () => {
  localStorage.clear();
  window.location = '/';
};

const getAxiosInstance = (baseURL) => {
  const token = localStorage.getItem('__a_token');

  const headers = {
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  };

  const axiosInstance = axios.create({
    baseURL,
    headers,
  });

  async function refreshAccessToken() {
    const refreshToken = localStorage.getItem('__a_refresh_token');

    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    try {
      const response = await axios.post(
        `${baseURL}${environment.api.refreshToken}`,
        {
          refreshToken,
        },
      );

      const newAccessToken = response.data.access_token;
      const newRefreshToken = response.data.refresh_token;

      localStorage.setItem('__a_token', newAccessToken);
      localStorage.setItem('__a_refresh_token', newRefreshToken);

      return newAccessToken;
    } catch (error) {
      console.error('Error refreshing access token:', error.response ? error.response.data : error.message);
      throw error;
    }
  }

  axiosInstance.interceptors.response.use(
    (response) => {
      if ([200, 201].includes(response.status)) {
        const result = response.data;
        if (_.isObject(result.isObject)) {
          result.statusCode = response.status;
        }
        return response.data;
      }
      return Promise.reject(response);
    },
    async (error) => {
      if (error.response?.status === 413) {
        return Promise.reject(new Error('updateImageLess'));
      }
      const { statusCode } = error.response.data;

      const originalRequest = error.config;
      if (statusCode) {
        if (statusCode === 401 && window.location.pathname !== '/') {
          if (!originalRequest._retry) {
            originalRequest._retry = true;
            try {
              const newAccessToken = await refreshAccessToken();
              originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
              return axiosInstance(originalRequest);
            } catch (error) {
              console.log('error', error);
              handleLogOut();
            }
          } else {
            handleLogOut();
          }
        }
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.response.data);
    },
  );

  return axiosInstance;
};

const request = (url, data = {}, method = 'POST') => {
  try {
    const API = getAxiosInstance(environment.baseURL);
    switch (method) {
      case 'GET':
        return API.get(url, { params: data });
      case 'PUT':
        return API.put(url, data);
      case 'DELETE':
        return API.delete(url, data);
      default:
        return API.post(url, data);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export default request;
