import React, { useState, useEffect } from 'react';
import { Form, Input, Col, Row, Button, Select, InputNumber, message as Alert, Upload, Modal } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import { fileUpload, productCreate, productUpdate } from './product.service';
import { supplierList } from '../supplier/supplier.service';
import { replenishmentCreate, replenishmentList, replenishmentUpdate } from '../replenishment/replenishment.service';
import './style.less';
import { productCategoryList } from '../product-category/product-category.service';
import { useSelector } from 'react-redux';
import environment from 'environments/environment';
import Helper from 'utils/helpers';
import Checkbox from 'antd/lib/checkbox/Checkbox';
const { Option } = Select;

const ProductEditForm = ({ item, onCallback, isEditModal, setIsEditModal }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [suppliers, setSuppliers] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [haveImage, setHaveImage] = useState(false);
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const onFileUploadChange = (file) => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = document.createElement('img');
        img.src = reader.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = 1500;
          canvas.height = 1000;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, 1500, 1000);
          canvas.toBlob(resolve);
        };
      };
      setHaveImage(true);
    });
  };
  const fakeUpload = async (options) => {
    const { onSuccess } = options;
    onSuccess('OK');
  };
  const onchangeCategory = (value) => {
    const categoryfind = productCategory.find(cate => cate.id === value);
    form.setFieldsValue({ method: categoryfind?.parentProductCategory.exportStrategy });
  };
  const onRemove = () => {
    setHaveImage(false);
  };
  useEffect(() => {
    productCategoryList({ warehouseId: currentWarehouse.id, status: 'ENABLE' }).then(result => {
      setProductCategory(result.data);
    });
    supplierList({ warehouseId: currentWarehouse.id, status: 'ENABLE' })
      .then(result => {
        setSuppliers(result.data);
      });
    if (item?.image) {
      setHaveImage(true);
    }
  }, [currentWarehouse, isEditModal, item]);

  // useEffect(() => {
  //   if(item?.id){
  //     replenishmentList({warehouseId: currentWarehouse?.id, masterProductId: item.id}).then(result=>{
  //       if(result.totalItem > 0){
  //         item.replenishments= result?.data;
  //       }
  //     })
  //   }
   
  // }, [currentWarehouse, item]);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);
  const initValues = {
    image: item?.image ? [{
      url: item.image,
    }] : [],
    name: item ? item?.name : '',
    productCategoryId: item ? item?.productCategory?.id : undefined,
    supplierIds: item ? item?.suppliers?.map(sup => sup.id) : undefined,
    capacity: item ? item?.capacity : null,
    stogareTime: item ? item?.stogareTime : 100,
    method: item ? item?.productCategory?.parentProductCategory?.exportStrategy : undefined,
    barCode: item ? item?.barCode : undefined,
    purchasePrice: item ? item?.purchasePrice : undefined,
    salePrice: item ? item?.salePrice : undefined,
    retailPrice: item ? item?.retailPrice : undefined,
    VAT: item ? item?.VAT : undefined,
    DVT: item ? item.DVT : undefined,
    packing: item ? item.packing : undefined,
    isResources: item ? item.isResources : undefined,
    length: item ? item.length : undefined,
    width: item ? item.width : undefined,
    height: item ? item.height : undefined,
    discount: item ? item.discount : undefined,
    isActive: item ? item.isActive : true,
    code: item ? item?.code : undefined,
    status: item?.status,
    min: item ? item?.replenishments[0]?.min : undefined,
    max: item ? item?.replenishments[0]?.max : undefined,
  };
  const onCancel = () => {
    const valueForm = form.getFieldsValue();
    if (valueForm?.image) {
      const image = valueForm?.image?.map(img => {
        if (img?.url) {
          return {
            url: img.url,
          };
        }
        return img;
      });
      valueForm.image = image;
    }
    const isDefined = Object.values(valueForm).some(value => value !== undefined);
    const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);
    // console.log(JSON.stringify(initValues))
    // console.log(JSON.stringify(valueForm))
    if (!isDefined || isUnChange) {
      setIsEditModal(false);
      return true;
    }
    Helper.conFirm(setIsEditModal);
  };

  return (
    <Modal
      visible={isEditModal}
      title={item ? lang.updateProduct : lang.createProduct}
      onCancel={onCancel}
      width={1024}
      centered
      footer={null}
      wrapClassName="product-modal"
      afterClose={() => {
        setHaveImage(false);
        form.resetFields();
      }}
      forceRender={true}
    >
      <Form
        colon={false}
        form={form}
        requiredMark={false}
        layout="vertical"
        name="productEditForm"
        onFinish={async (values) => {
          try {
            setLoading(true);
            if (values?.image[0]?.originFileObj) {
              if (values?.image[0].size > environment.MAX_IMAGE_SIZE) {
                throw new Error('updateImageLess');
              }
              const formda = new FormData();
              formda.append('image', values?.image[0]?.originFileObj);
              formda.append('type', values?.image[0]?.type);
              const res = await fileUpload(formda);
              values.image = res.image_url;
            } else if (values.image[0]?.url) {
              values.image = values.image[0].url;
            }
            if (!item) { // Create
              const response = await productCreate({ ...values, warehouseId: currentWarehouse.id });
              if (values?.min || values?.max) {
                const { name, min, max, productCategoryId } = values;
                replenishmentCreate({ productName: name, min, max, productCategoryId, masterProductId: response?.id, warehouseId: currentWarehouse.id })
                  .then(() => {
                    setLoading(false);
                  })
                  .catch((e) => setLoading(false));
              }
              Alert.success(lang.createSuccessfully);
              setLoading(false);
              onCallback();
            } else { // Update
              const { name, min, max, productCategoryId } = values;
              if ( item?.replenishments[0] && (values?.min !== item?.replenishments[0]?.min || values?.max !== item?.replenishments[0]?.max)) {
                await replenishmentUpdate({ productName: name, min, max, productCategoryId, masterProductId: item.id, warehouseId: currentWarehouse.id }, item?.replenishments[0]).then(result => {
                  setLoading(false);
                })
                  .catch((e) => setLoading(false));
              }
              else{
                replenishmentCreate({ productName: name, min, max, productCategoryId, masterProductId: item?.id, warehouseId: currentWarehouse.id })
                  .then(() => {
                    setLoading(false);
                  })
                  .catch((e) => setLoading(false));
              }
              delete values.min;
              delete values.max;
              productUpdate(values, item)
                .then(result => {
                  Alert.success(lang.updateSuccessfully);
                  onCallback();
                  setLoading(false);
                })
                .catch((e) => { Alert.error(e.message); setLoading(false); });
            }
          } catch (error) {
            if (error.message === 'updateImageLess') {
              Alert.error(lang.updateImageLess);
              setLoading(false);
              return false;
            }
            Alert.error(error.message);
            setLoading(false);
          }
        }}
        initialValues={initValues}
      >
        <Row>
          <Col span={8}>
            <Form.Item
              name="image"
              label={lang.productImage}
              extra={lang.imageExtra}
              // rules={[
              //   {
              //     required: true,
              //     message: lang.requiredImage,
              //   },
              //   () => ({
              //     validator(_, value) {
              //       if (value?.file?.status !== 'removed') {
              //         return Promise.resolve();
              //       }
              //       return Promise.reject(new Error(lang.requiredImage));
              //     },
              //   }),
              // ]}
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                beforeUpload={(file) => onFileUploadChange(file)}
                accept="image/*"
                listType="picture-card"
                maxCount={1}
                showUploadList={true}
                customRequest={fakeUpload}
                onRemove={onRemove}
                className="UploadImage"
              >
                {!haveImage && `+ ${lang.upload}`}
              </Upload>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Row gutter={24} type="flex">
              {item && <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item
                  label={lang.code}
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: lang.messageInput,
                    },
                  ]}
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>}
              {item && <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item name="status" label={lang.status} >
                  <Select placeholder={lang.status}>
                    <Option value="ENABLE">{lang.ENABLE}</Option>
                    <Option value="DISABLE">{lang.DISABLE}</Option>
                  </Select>
                </Form.Item>
              </Col>}
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item
                  name="name"
                  label={lang.productName}
                  rules={[
                    {
                      required: true,
                      message: lang.requiredProductName,
                    },
                    {
                      max: 100,
                      message: lang.messageLong,
                    },
                  ]}
                >
                  <Input placeholder={lang.placeholderName} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item name="productCategoryId" label={lang.productCategory}
                  rules={[
                    {
                      required: true,
                      message: lang.requiredCategory,
                    },
                  ]}>
                  <Select placeholder={lang.placeholderSelectProductCate} onChange={onchangeCategory} showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    }>
                    {productCategory?.map(item => (
                      <Option key={item.id} value={item.id}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item name="supplierIds" label={lang.supplier}
                // rules={[
                //   {
                //     required: true,
                //     message: lang.requiredSupplier,
                //   }]}
                >
                  <Select placeholder={lang.placeholderSelectSupplier} showSearch mode="multiple"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                  // maxTagCount='responsive'
                  >
                    {suppliers?.map(item => (
                      <Option key={item.id} value={item.id}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item
                  name="capacity"
                  label={lang.capacity}
                // rules={[
                //   {
                //     required: true,
                //     message: lang.requiredCapacity,
                //   },
                // ]}
                >
                  <InputNumber style={{ width: '100%' }} placeholder={lang.placeholderCapacity} min={1} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item
                  name="stogareTime"
                  label={lang.storageTime}
                // rules={[
                //   {
                //     required: true,
                //     message: lang.requiredStoreTime,
                //   },
                // ]}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item name="method" label={lang.methodExport}>
                  <Select placeholder={''} disabled={true}>
                    <Option value="LIFO">LIFO</Option>
                    <Option value="FIFO">FIFO</Option>
                    <Option value="FEFO">FEFO</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item
                  noStyle
                  shouldUpdate
                >
                  {({ getFieldValue }) => <Form.Item
                    name="min"
                    label={lang.minQuantity}
                    // rules={[
                    //   {
                    //     required: !!getFieldValue('max'),
                    //     message: lang.requiredMin,
                    //   },
                    // ]}
                    disabled={item?.replenishments.length > 0}
                  >
                    <InputNumber min={0} style={{ width: '100%' }} placeholder={lang.placeholderMin} max={getFieldValue('max') && getFieldValue('max') - 1} />
                  </Form.Item>}
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item
                  noStyle
                  shouldUpdate
                >
                  {({ getFieldValue }) =>
                    <Form.Item
                      name="max"
                      label={lang.maxQuantity}
                    // rules={[
                    //   {
                    //     required: !!getFieldValue('min'),
                    //     message: lang.requiredMax,
                    //   },
                    // ]}
                    >
                      <InputNumber style={{ width: '100%' }} placeholder={lang.placeHolderMax} min={getFieldValue('min') + 1 || 1} />
                    </Form.Item>
                  }
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item
                  name="barCode"
                  label={lang.defaultCode}
                // rules={[
                //   {
                //     required: true,
                //     message: lang.requiredDefaultCode,
                //   },
                // ]}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.placeHolderDefaultCode} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item name="purchasePrice" label={lang.cost}>
                  <InputNumber style={{ width: '100%' }} value={item?.salePrice} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} placeholder={lang.cost} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item name="salePrice" label={lang.salePrice}>
                  <InputNumber style={{ width: '100%' }} value={item?.salePrice} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} placeholder={lang.salePrice} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item name="retailPrice" label={lang.retailPrice}>
                  <InputNumber style={{ width: '100%' }} value={item?.salePrice} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} placeholder={lang.retailPrice} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item name="VAT" label={lang.VAT} >
                  <InputNumber style={{ width: '100%' }} max={100} min={0} placeholder={lang.VAT} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item name="DVT" label={lang.DVT} >
                  <Input style={{ width: '100%' }} placeholder={lang.DVT} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item name="packing" label={lang.packingRule}
                  rules={[
                    {
                      required: true,
                      message: lang.requiredPackingRule,
                    },
                  ]}>
                  <Input style={{ width: '100%' }} placeholder={lang.packingRule} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item name="isResources" label={lang.isResources} >
                  <Select placeholder={lang.isResources}>
                    <Option key={0} value={true}>Nguyên liệu</Option>
                    <Option key={1} value={false}>Thành phẩm</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item name="length" label={lang.length} >
                  <InputNumber style={{ width: '100%' }} placeholder={lang.length} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item name="width" label={lang.width} >
                  <InputNumber style={{ width: '100%' }} placeholder={lang.width} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item name="height" label={lang.height} >
                  <InputNumber style={{ width: '100%' }} placeholder={lang.height} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item name="discount" label={lang.discount} >
                  <InputNumber style={{ width: '100%' }} placeholder={lang.discount} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item
                  name="isActive"
                  valuePropName="checked"
                  label={' '}
                >
                  <Checkbox>{lang.isActive}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

ProductEditForm.propTypes = {
  item: PropTypes.object,
  onCallback: PropTypes.func,
  isEditModal: PropTypes.bool,
  setIsEditModal: PropTypes.func,
};

export default ProductEditForm;
