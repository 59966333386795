import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Modal, Card, Row, Col, Input, Tag, message } from 'antd';
import { KeyOutlined, PlusOutlined, DeleteOutlined, SearchOutlined, ExclamationCircleOutlined, EditOutlined, UnlockOutlined } from '@ant-design/icons';

import { unlockUser, userDelete, userList } from './user.service';
import { warehouseListAll } from '../warehouse/warehouse.service';

import UserEditForm from './user-edit.form';
import UserResetPasswordForm from './user-reset-password.form';

import './style.less';
import moment from 'moment';
import { formatDatetime } from 'utils/constants';
import _get from 'lodash/get';
import TableCustom from 'components/TableCustom/table';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const UserList = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [warehouses, setWarehouses] = useState([]);
  const [items, setItems] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [flag, setFlag] = useState(false);
  const [item, setItem] = useState({});
  const [isEditModal, setIsEditModal] = useState(false);
  const [isResetPasswordModal, setIsResetPasswordModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getUsers = () => {
    setLoading(true);

    const params = {
      username: keyword,
    };

    userList(params)
      .then(result => {
        const rsfilter = result?.map(rs => {
          const role = rs.roles?.filter(role => role.name !== 'default-roles-cloudwms');
          rs.roles = role;
          return rs;
        });
        setItems(rsfilter);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setLoading(true);
    warehouseListAll()
      .then(result => {
        setWarehouses(result?.data);
        getUsers();
        setLoading(false);
      })
      .catch(e => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag]);

  const columns = [
    {
      title: lang.username,
      key: 'username',
      width: '20%',
      dataIndex: 'username',
    },
    {
      title: lang.name,
      key: 'name',
      width: '20%',
      render: result => `${result?.firstName} ${result?.lastName}`,
    },
    {
      title: lang.role,
      key: 'role',
      dataIndex: 'roles',
      width: '25%',
      render: (record) => {
        return (
          <Tag>{_get(record[0], 'name', '')}</Tag>
        );
      },
    },
    {
      title: lang.createDate,
      dataIndex: 'createdTimestamp',
      key: 'createdTimestamp',
      width: '20%',
      render: value => moment.unix(value / 1000).format(formatDatetime),
    },
    {
      title: lang.action,
      align: 'center',
      width: '15%',
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex' }}>
          <Button
            key="btn-reset"
            title={lang.resetPassword}
            size="small"
            type="link"
            icon={<KeyOutlined />}
            onClick={() => {
              const userDetail = items.find((item) => item.id === record.id);
              setItem(userDetail);
              setIsResetPasswordModal(true);
            }}
          />
          <Button
            key="btn-edit"
            size="small"
            title={lang.btnEdit}
            type="link"
            icon={<EditOutlined />}
            style={{ margin: '0 20px' }}
            onClick={() => {
              const userDetail = items.find((item) => item.id === record.id);
              const warehouseIdsTemp = [];
              for (let i = 0; i < warehouses.length; i++) {
                if (_get(warehouses[i], 'userIds', []) !== null) {
                  if (_get(warehouses[i], 'userIds', []).includes(userDetail.id)) {
                    warehouseIdsTemp.push(warehouses[i]?.id);
                  }
                }
              }
              setItem({
                ...userDetail,
                warehouseIds: warehouseIdsTemp,
              });
              setIsEditModal(true);
            }}
          />
          {!record.enabled && <Button
            key="btn-unlock"
            size="small"
            type="link"
            icon={<UnlockOutlined />}
            onClick={async () => {
              try {
                await unlockUser({ id: record.id, enabled: !record.enabled });
                message.success(lang.unlockAccount);
                setFlag(!flag);
              } catch (error) {
                message.error(error.message);
              }
            }}
          />}
        </div>
      ),
    },
  ];

  const extraButton = [
    <Button
      key="btn-complete"
      type="primary"
      onClick={() => {
        setItem();
        setIsEditModal(true);
      }}
    >
      {lang.btnCreate}
      <PlusOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/admin',
      breadcrumbName: lang.admin,
    },
    {
      path: '/admin/user',
      breadcrumbName: lang.user,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.users}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Row gutter={[16, 16]}>
            <Col style={{ marginTop: 4 }}>
              <label>{lang.username}</label>
            </Col>
            <Col col={16}>
              <Input placeholder={lang.username} onChange={(value) => setKeyword(value.target.value)} />
            </Col>
            <Col col={8}>
              <Button
                type={'primary'}
                ghost
                icon={<SearchOutlined />}
                onClick={() => getUsers()}
              >{lang.search}</Button>
            </Col>
          </Row>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.user}</h3></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        const successList = [];
                        for (let i = 0; i < selectedRowKeys.length; i++) {
                          userDelete({
                            id: selectedRowKeys[i],
                          })
                            .then(result => {
                              successList.push(selectedRowKeys[i]);
                            })
                            .catch((e) => message.error(e.message));
                        }

                        setFlag(!flag);
                        setSelectedRowKeys([]);
                        Modal.success({
                          title: lang.deleteSuccessfully,
                          content: `${lang.idsHasBeenDeleted} ${JSON.stringify(selectedRowKeys)}`,
                        });
                      },
                      onCancel() {
                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="id"
          rowSelection={rowSelection}
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          pagination={{ showSizeChanger: true }}
          scroll={{ x: 600 }}
        />
      </Layout.Content>
      <UserEditForm
        item={item}
        warehouses={warehouses}
        onCallback={(value) => {
          setFlag(!flag);
          setIsEditModal(false);
        }}
        isEditModal={isEditModal}
        setIsEditModal={setIsEditModal}
      />
      <UserResetPasswordForm
        item={item}
        onCallback={(value) => {
          setIsResetPasswordModal(false);
        }}
        isResetPasswordModal={isResetPasswordModal}
        setIsResetPasswordModal={setIsResetPasswordModal}
      />
    </Layout>
  );
};

export default UserList;
