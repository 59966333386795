import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Row, Col, Dropdown, Menu, message } from 'antd';
import { LockOutlined, EyeInvisibleOutlined, EyeOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import './styles.less';
import bgImg from 'assets/background.png';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';

import { setLanguage } from 'providers/I18nProvider/actions';
import { login } from 'providers/AuthProvider/actions';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import logo from 'assets/PinNow-07.png';
import iconFlagEn from 'assets/icons/united-states-flag.png';
import iconFlagVi from 'assets/icons/vietnam-flag.png';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const lang = getLanguages(t);
  const locale = useSelector((state) => state.i18n.locale);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (locale === null) {
      dispatch(setLanguage({ locale: 'vi' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onFinish = (values) => {
    setLoading(true);
    dispatch(login(values))
      .then((result) => {
        const isAdmin = _get(result, 'roles', '').includes('admin');

        setLoading(false);
        if (isAdmin) {
          navigate('/admin');
        } else {
          navigate('/dashboard');
        }
      })
      .catch(err => {
        message.error(err);
        setLoading(false);
      });
  };

  const changeLanguage = (locale) => {
    dispatch(setLanguage({ locale }));
  };

  const languages = (
    <Menu>
      <Menu.Item onClick={() => changeLanguage('vi')}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <img src={iconFlagVi} width={24} alt="Vietnam Language" />
          </div>
          <div style={{ marginLeft: 8, marginTop: 2 }}>Vietnam</div>
        </div>
      </Menu.Item>
      <Menu.Item onClick={() => changeLanguage('en')}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <img src={iconFlagEn} width={24} alt="English Language" />
          </div>
          <div style={{ marginLeft: 8, marginTop: 2 }}>English</div>
        </div>
      </Menu.Item>
    </Menu>
  );

  const languageSelected = (locale) => {
    switch (locale) {
      case 'vi':
        return <img src={iconFlagVi} width={30} alt="Vietnam Language" />;
      case 'en':
        return <img src={iconFlagEn} width={30} alt="English Language" />;
      default:
        return <img src={iconFlagVi} width={30} alt="Vietnam Language" />;
    }
  };

  return (
    <div className="login-page">
      <Row justify="space-between" style={{ height: window.innerHeight }}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} style={{ alignSelf: 'center' }} className="paddingCol">
          <div className="login-logo">
            <img src={logo} alt="logo" style={{ height: 46 }} />
            <h3 className="login-title" style={{ marginTop: 20, marginBottom: 20 }}>
              {lang.login}
            </h3>
            <p>{lang.slogan}</p>
          </div>
          <div>
            <Form
              name="loginForm"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              layout="vertical"
            >
              <Form.Item
                label={lang.username}
                name="username"
                rules={[
                  { required: true, message: lang.messageUsername },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={lang.placeholderUsername}
                />
              </Form.Item>
              <Form.Item
                label={lang.password}
                name="password"
                rules={[{ required: true, message: lang.messagePassword }]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder={lang.placeholderPassword}
                  iconRender={(visible) =>
                    visible ? (
                      <EyeOutlined style={{ color: 'black' }} />
                    ) : (
                      <EyeInvisibleOutlined style={{ color: 'black' }} />
                    )
                  }
                />
              </Form.Item>
              <Form.Item>
                {loading === false ? (
                  <Button type="primary" htmlType="submit" block className="login-form-button">
                    {lang.login}
                  </Button>
                ) : (
                  <Button type="primary" loading={loading} block className="login-form-button">
                    {lang.loading}
                  </Button>
                )}
              </Form.Item>
            </Form>
          </div>
          <div style={{ position: 'relative' }}>
            <Dropdown overlay={languages} placement="bottomCenter">
              <div
                style={{
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 16,
                  width: 100
                }}
              >
                {languageSelected(locale)}
                <DownOutlined style={{ fontSize: 10, marginLeft: 8 }} />
              </div>
            </Dropdown>
          </div>
          <div style={{ paddingTop: '25vh' }}>Hệ thống akaWMS là giải pháp được xây dựng theo định hướng chuyển đổi số, hỗ trợ tích hợp hệ thống linh hoạt với nhiều lợi thế về quy trình và kỹ thuật vượt trội, giúp tối ưu hóa phương pháp quản lý kho truyền thống; từ đó nâng cao hiệu quả vận hành kho bãi và mức độ chuyên môn hóa.</div>
          <div style={{ paddingTop: '3vh', fontWeight: 'bold' }}>Sản phẩm thuộc bộ Logistics Suite và được phát triển bởi công ty FPT Software.</div>
        </Col>
        <Col
          xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}
          style={{
            backgroundImage: `url(${bgImg})`,
            width: '100%',
            backgroundSize: 'cover',
            overflow: 'hidden',
          }}
        > <div style={{ display: 'flex', flexDirection: 'row', padding: 24, backgroundColor: '#FAAF41', minHeight: 180, marginTop: window.innerHeight / 2 - 150 }}>
            <div style={{ marginLeft: 24 }}>
              <div style={{ fontSize: 30, fontWeight: 900, color: 'white' }}>{lang.akaName}</div>
              <div style={{ fontSize: 16, fontWeight: 400, color: 'white' }}>{lang.akaDescription}</div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
