import {
  loginSuccess,
  loginFail,
  logoutFail,
  logoutSuccess,
  setCurrentUser,
  setCurrentWarehouse,
  changePasswordSuccess,
  changePasswordFail,
  forgotPasswordSuccess,
  forgotPasswordFail,
  uploadImageFail,
  uploadImageSuccess,
} from './actions';
import {
  LOGIN,
  LOGOUT,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  UPLOAD_IMAGE,
} from './types';
import { put, takeLeading, call, takeEvery } from 'redux-saga/effects';
import request from 'utils/request';
import environment from 'environment';
import { message as Alert } from 'antd';
import i18n from 'i18next';
import jwt from 'jsonwebtoken';
import _get from 'lodash/get';

const lang = () => ({
  errorsHappen: i18n.t('errorsHappen'),
  imageUploadExceeds: i18n.t('imageUploadExceeds'),
  messageLogin: i18n.t('messageLogin'),
  mailNotExistError: i18n.t('mailNotExistError'),
  passwordError: i18n.t('passwordError'),
});

function* login(action) {
  try {
    const { username, password } = action.payload;
    const data = yield call(request, environment.api.login, {
      username,
      password,
    });

    if (!data.access_token) {
      return;
    }
    const tokenDecode = jwt.decode(data.access_token);
    localStorage.setItem('__a_token', data.access_token);
    localStorage.setItem('__a_refresh_token', data.refresh_token);
    yield put(loginSuccess({
      roles: JSON.stringify(_get(tokenDecode, 'realm_access.roles', [])),
    }, action.meta));

    const user = yield call(request, environment.api.getCurrentUser, {}, 'GET');
    yield put(setCurrentUser({
      ...user,
      roles: JSON.stringify(_get(tokenDecode, 'realm_access.roles', [])),
      refresh_token: data.refresh_token,
    }));
    if (user?.warehouses.length > 0) {
      yield put(setCurrentWarehouse(user?.warehouses[0]));
    }
  } catch (error) {
    yield put(loginFail(error?.message, action.meta));
  }
}

function* logout(action) {
  try {
    yield call(request, '/auth/logout', {
      refresh_token: action?.payload,
    });
    yield localStorage.removeItem('__a_token');
    yield put(logoutSuccess({}, action.meta));
  } catch (error) {
    Alert.error(lang().errorsHappen);
    yield put(logoutFail(lang().errorsHappen, action.meta));
  }
}

function* changePassword(action) {
  try {
    const { currentPassword, newPassword } = action.payload;
    const data = yield call(request, environment.api.changePassword, {
      currentPassword,
      newPassword,
    });

    localStorage.setItem('__a_token', data.result.sessionToken);
    yield put(changePasswordSuccess({}, action.meta));
  } catch (error) {
    if (error?.code === 101) {
      return Alert.error(lang().passwordError);
    }
    Alert.error(error.error);
    yield put(changePasswordFail(error.error, action.meta));
  }
}

function* forgotPassword(action) {
  try {
    const { email } = action.payload;
    yield call(request, environment.api.forgotPassword, { email });

    yield put(forgotPasswordSuccess({}, action.meta));
  } catch (error) {
    if (error.code === 101) {
      Alert.error(lang().mailNotExistError);
    } else {
      Alert.error(error.error);
    }
    yield put(forgotPasswordFail(error.error, action.meta));
  }
}

function* uploadImage(action) {
  try {
    const { file, type } = action.payload;
    const formDataUploadPhoto = new FormData();
    formDataUploadPhoto.append('file', file);
    formDataUploadPhoto.append('type', type);
    if (file?.size <= environment.MAX_IMAGE_SIZE) {
      const result = yield call(request, environment.api.uploadImage, formDataUploadPhoto);
      yield put(uploadImageSuccess(result, action.meta));
      return;
    }
    Alert.warning(lang().imageUploadExceeds);
    yield put(uploadImageSuccess({}, action.meta));
  } catch (error) {
    yield put(uploadImageFail(error, action.meta));
  }
}

export default function* watchAuth() {
  yield takeLeading(LOGIN, login);
  yield takeLeading(LOGOUT, logout);
  yield takeLeading(CHANGE_PASSWORD, changePassword);
  yield takeLeading(FORGOT_PASSWORD, forgotPassword);
  yield takeEvery(UPLOAD_IMAGE, uploadImage);
}
