import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Card, Row, Col, Select, Input, Tag, Form, message } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { bookingDetail, bookingList, orderCheck } from './picking.service';
import { useDispatch, useSelector } from 'react-redux';
import { bookingOrderSet } from 'providers/OutboundProvider/actions';
import './style.less';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import { defaultPage, formatDateFull, formatDatetime } from 'utils/constants';
import { pickBy } from 'lodash';
import TableCustom from 'components/TableCustom/table';
import jsPdf from "jspdf";
import html2canvas from "html2canvas";
const defaultSort = {
  sortDirection: 'desc',
  sortBy: 'createDate',
};

const { Option } = Select;

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const PickingList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Roles
  const { user, currentWarehouse } = useSelector((state) => state.auth);
  const isStaff = _get(user, 'roles', '').includes('staff');

  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [items, setItems] = useState([]);
  const [itemsDetail, setItemsDetail] = useState([]);
  //Pagination
  const [params, setParams] = useState({ status: 'NEW', ...defaultPage });
  const [sortedInfo, setSortedInfo] = useState(defaultSort);
  // const [pageSize, SetPageSize] = useState(10);
  // const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeDetail, SetPageSizeDetail] = useState(10);
  const [currentPageDetail, setCurrentPageDetail] = useState(1);
  const [searchCode, setSearchCode] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [form] = Form.useForm();
  const [loadingExport, setLoadingExport] = useState(false);
  //
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const fetchDetail = async (id) => {
    try {
      setLoadingDetail(true);
      const data = await bookingDetail(id);
      setItemsDetail(data?.productOrders);
      setLoadingDetail(false);
    } catch (error) {
      message.error(error.message);
      setLoadingDetail(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    bookingList({ warehouseId: currentWarehouse.id, status: 'NEW' })
      .then(result => {
        setSearchCode(result.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [currentWarehouse]);

  useEffect(() => {
    setLoading(true);
    bookingList({ ...params, ...sortedInfo, warehouseId: currentWarehouse.id })
      .then(result => {
        setItems(result.data);
        setTotalItems(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [params, sortedInfo, currentWarehouse]);
  const columns = [
    {
      title: lang.orderCode,
      key: 'orderCode',
      // width: '20%',
      dataIndex: 'code',
    },
    {
      title: lang.vansCode,
      key: 'vansCode',
      dataIndex: 'boothCode',
      sorter: true,
    },
    {
      title: lang.userName,
      key: 'nameCustomer',
      // width: '30%',
      dataIndex: 'nameCustomer',
      sorter: true,
    },
    {
      title: lang.orderDate,
      dataIndex: 'createDate',
      key: 'createDate',
      // width: '20%',
      render: (value) => moment(value, formatDateFull).format(formatDatetime),
      sorter: true,
    },
    {
      title: lang.address,
      dataIndex: 'deliveryAdress',
      key: 'deliveryAdress',
      // width: '20%',
      sorter: true,
    },
    {
      title: lang.deliveryTime,
      dataIndex: 'deliveryTime',
      key: 'deliveryTime',
      // width: '20%',
      render: value => value && moment(value, formatDateFull).format(formatDatetime),
      sorter: true,
    },
    {
      title: lang.status,
      key: 'status',
      dataIndex: 'status',
      // width: '15%',
      render: (record) => {
        return (
          <Tag>{record}</Tag>
        );
      },
    },
  ];

  const columnsDetail = [
    {
      title: lang.defaultCode,
      render: record => record?.masterProduct?.code,
      key: 'barcode',
      width: '15%',
    },
    {
      title: lang.productName,
      render: record => record?.masterProduct?.name,
      key: 'name',
      width: '15%',
    },
    {
      title: lang.orderQuantity,
      dataIndex: 'total',
      key: 'total',
      width: '15%',
    },
    // {
    //   title: lang.pickingquantity,
    //   dataIndex: 'pickingQuantity',
    //   key: 'pickingQuantity',
    //   width: '15%',
    //   render: (value) => value || 0,
    // },
    {
      title: lang.status,
      key: 'status',
      dataIndex: 'status',
      width: '10%',
      render: (record) => {
        return (
          <Tag>{record}</Tag>
        );
      },
    },
  ];

  const extraButton = [
    <Button
      key="btn-complete"
      disabled={isStaff}
      type="primary"
      onClick={async () => {
        try {
          if (selectedRowKeys.length > 0) {
            await orderCheck({ ids: selectedRowKeys?.map(order => order.id), userId: user?.userInfo?.sub });
            dispatch(bookingOrderSet({
              pickingOrder: selectedRowKeys,
              pickingProducts: selectedRowKeys?.reduce((prev, curr) => {
                const productOrders = curr?.productOrders?.filter(product => product.status === 'ENABLE' || product.status === 'PENDING')?.map(product => {
                  product.orderCode = curr.code;
                  return product;
                });
                return [...prev, ...productOrders];
              }, []),
            }))
          }
          // dispatch(pickingOrderCreate());
          navigate('create');
        } catch (error) {
          const splitMessage = error?.message?.split(/\s(.*)/s);
          message.error(splitMessage[0] + ' ' + lang.messeagePicking);
        }

      }}
    >
      {lang.btnCreate}
      <PlusOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '',
      breadcrumbName: 'Home',
    },
    {
      path: '',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/sale-order',
      breadcrumbName: lang.saleOrder,
    },
  ];

  return (
    <Layout className="picking-list">
      <PageHeader
        ghost={false}
        title={lang.saleOrder}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content style={{ margin: '12px 24px' }}>
        <Card size="small" className="cardSearch">
          <Form form={form} className="customFormSearch" onFinish={(value) => {
            const cleanValue = pickBy(value, v => v !== undefined && v !== '');
            if (!cleanValue?.orderCode && !cleanValue?.status) {
              cleanValue.status = 'NEW';
            }
            if (cleanValue?.orderCode) {
              cleanValue.status = undefined;
            }
            setParams({ ...cleanValue, page: 1, limit: params.limit });
          }}>
            <Row gutter={[16, 16]}>
              <Col xxl={4} sm={12} md={6} xs={16}>
                <Form.Item label={lang.orderCode} name="orderCode">
                  <Select placeholder={lang.orderCode} showSearch allowClear>
                    {searchCode?.map(item => <Option key={item.id} value={item.code}>{item.code}</Option>)}
                  </Select>
                </Form.Item>
              </Col>
              <Col xxl={4} md={6} sm={12}>
                <Form.Item shouldUpdate>
                  {({ getFieldValue }) => {
                    return <Form.Item label={lang.status} name="status">
                      <Select disabled={getFieldValue('orderCode')} placeholder={lang.status} showSearch allowClear>
                        <Option key={'NEW'} value={'NEW'}>{'NEW'}</Option>
                        <Option key={'PICKING'} value={'PICKING'}>{'PICKING'}</Option>
                      </Select>
                    </Form.Item>;
                  }}
                </Form.Item></Col>
              <Col xxl={4} md={6} sm={12}>
                <Form.Item name="customerName">
                  <Input placeholder={lang.keyword} allowClear={true} />
                </Form.Item>
              </Col>
              <Col sm={3}>
                <Form.Item>
                  <Button
                    type={'primary'}
                    ghost
                    icon={<SearchOutlined />}
                    htmlType="submit"
                  >{lang.search}</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Card size="small" className="cardSearch">
          <Row style={{ marginBottom: 0 }}>
            <Col span={12} style={{ display: 'flex' }}>
              <h3>{lang.titlePicking}</h3>
              <div style={{ lineHeight: '27px', marginLeft: '100px' }}>{`Tổng số đơn hàng: ${totalItems}`}</div>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {selectedRowKeys.length > 0 && (
                <Button
                  key="btn-export"
                  type="primary"
                  ghost
                  loading={loadingExport}
                  onClick={async () => {
                    setLoadingExport(true);
                    const dataProduct = selectedRowKeys?.reduce((prev, curr) => [...prev, ...curr?.productOrders], [])?.reduce((prev, curr) => {
                      const finded = prev?.find(pre => pre?.masterProduct?.barCode === curr?.masterProduct?.barCode);
                      if (finded) {
                        finded.total += curr.total;
                        return prev;
                      }
                      return [...prev, curr];
                    }, []);
                    const content = `
                <div style="display: grid ;line-height: 0.5; width: 900px; font-family: "Times New Roman", Times, serif;">
                <div style="display: flex; justify-content: space-between;">
                  <div>
                    <h3>Công ty TNHH PinNow Việt Nam</h3>
                    <p>134 Bạch Đằng, Phường 2, Q.Tân Bình,</p>
                    <p>0316954732</p>
                  </div>
                  <div style="text-align: center;">
                    <h3>Mẫu số: 01-VT*</h3>
                    <p>Ban hành theo thông tư</p>
                    <p>số 200/2014/TT-BTC</p>
                    <p>ngày 22/12/2014 của Bộ</p>
                  </div>
                </div>
                <div style="text-align: center;">
                  <h1>PHIẾU XUẤT KHO</h1>
                  <p>Ngày ${moment().format('DD')} Tháng ${moment().format('MM')} Năm ${moment().format('YYYY')}</p>
                  <p>Số phiếu:</p>
                </div>
                <div style="display: flex; flex-flow: wrap">
                  <div style="width: 33%; margin-bottom: 10px;">Họ và tên người giao:</div>
                  <div style="width: 33%; margin-bottom: 10px;">MS Tài xế:</div>
                  <div style="width: 33%; margin-bottom: 10px;">MS xe: ${[...new Set(selectedRowKeys?.filter(order => order?.boothCode)?.map(order => order.boothCode))]?.join()}</div>
                  <div style="width: 33%; margin-bottom: 10px;">Xuất tại kho:</div>
                  <div style="width: 33%; margin-bottom: 10px;">Ngày giờ in:</div>
                </div>
              <div style="margin-bottom: 10px">Nội dung: Xuất háng bán ngày ${moment().format('DD')} Tháng ${moment().format('MM')} Năm ${moment().format('YYYY')} hàng theo menu….</div>
                <div>
                  <table border="1" style="width: 100%; border-collapse: collapse; line-height: 1;">
                    <thead>
                      <tr style="height: 30px;">
                        <th>STT</th>
                        <th>Tên hàng hóa</th>
                        <th>Mã hàng/ Itemcode</th>
                        <th>Đơn vị tính</th>
                        <th>Số lượng</th>
                        <th>Block</th>
                        <th>Shelf</th>
                        <th>Rack</th>
                        <th>Ghi chú</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style="text-align: center; height: 20px;">
                        <td>1</td>
                        <td>2</td>
                        <td>3</td>
                        <td>4</td>
                        <td>5</td>
                        <td>6</td>
                        <td>7</td>
                        <td>8</td>
                        <td>9</td>
                      </tr>
                      ${dataProduct?.map((product, index) => {
                      return `<tr style="text-align: center; height: 30px;">
                            <td>${index + 1}</td>
                            <td>${product.masterProduct.name}</td>
                            <td>${product.masterProduct.barCode}</td>
                            <td>${product.masterProduct.DVT}</td>
                            <td>${product.total}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>`
                    }).join(' \n')}
                <tr style="text-align: center; height: 30px;">
                        <td></td>
                        <td>Cộng</td>
                        <td></td>
                        <td></td>
                        <td>${dataProduct?.reduce((prev, curr) => prev + curr.total, 0)}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  <div style="margin-top: 10px">
                    <b><u>Xuất hàng theo SO:</u></b> Tổng ${selectedRowKeys.length} SO
                  </div>
                  <div style="display: flex; flex-flow: wrap; margin-top: 10px; text-align: center">
                    ${selectedRowKeys?.map(order => `<div style="width: 33%; margin-bottom: 10px;">${order.code}</div>`).join(' \n')}
                  </div>
                  <div style="display: flex; justify-content: space-between; margin-top: 10px">
                      <div style="text-align: center;">
                        <p style="font-weight: bold;">Người lập phiếu</p>
                        <p style="font-style: italic;">(Ký, họ tên)</p>
                      </div>
                      <div style="text-align: center;">
                        <p style="font-weight: bold;">Người giao hàng</p>
                        <p style="font-style: italic;">(Ký, họ tên)</p>
                      </div>
                      <div style="text-align: center;">
                        <p style="font-weight: bold;">Thủ kho</p>
                        <p style="font-style: italic;">(Ký, họ tên)</p>
                      </div>
                      <div style="text-align: center;">
                        <p style="font-weight: bold;">Giám đốc nhà máy</p>
                        <p style="font-style: italic;">(Ký, họ tên)</p>
                      </div>
                    </div>
                </div>
              </div>`;
                    var template = document.createElement('div');
                    template.innerHTML = content;
                    document.body.appendChild(template);
                    await html2canvas(template, { width: 1000, scale: 3, }).then((canvas) => {
                      let imgWidth = 208;
                      let imgHeight = canvas.height * imgWidth / canvas.width;
                      var imgData = canvas.toDataURL("image/png");
                      const pdf = new jsPdf("p", "mm", "a4");
                      pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
                      pdf.save(`Receipt${moment().format('DDMMYYYY')}.pdf`);
                    });
                    document.body.removeChild(template);
                    setLoadingExport(false);
                  }}
                >
                  {lang.exportPDF}
                </Button>
              )}
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <TableCustom
                rowKey="id"
                // rowSelection={!isStaff && rowSelection}
                rowSelection={params?.status === 'NEW' && {
                  onChange: (_, selectedRows) => {
                    setSelectedRowKeys(selectedRows);
                  }
                }}
                className="tableCustom"
                loading={loading}
                bordered
                columns={columns}
                dataSource={items}
                onChange={(pagination, filters, sorter) => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  if (pagination.pageSize !== params.limit) {
                    params.page = 1;
                  } else {
                    params.page = pagination.current;
                  }
                  if (sorter) {
                    setSortedInfo({ sortDirection: sorter.order, sortBy: sorter.field })
                  }
                  params.limit = pagination.pageSize;
                  setParams({ ...params });
                  setItemsDetail([]);
                }}
                pagination={{
                  showSizeChanger: true,
                  total: totalItems,
                  pageSize: params.limit,
                  current: params.page,
                }}
                scroll={{ x: 600 }}
                onRow={(record) => {
                  return {
                    onClick: async () => {
                      fetchDetail(record?.id);
                      setCurrentPageDetail(1);
                    },
                    style: { cursor: 'pointer' },
                  };
                }}
              />
            </Col>
            <Col span={24}>
              <TableCustom
                rowKey="id"
                loading={loadingDetail}
                size="small"
                className="tableCustom"
                bordered
                columns={columnsDetail}
                dataSource={itemsDetail}
                onChange={(pagination, filters, sorter) => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  setCurrentPageDetail(pagination.current);
                  SetPageSizeDetail(pagination.pageSize);
                }}
                pagination={{
                  showSizeChanger: true,
                  total: itemsDetail.length,
                  pageSize: pageSizeDetail,
                  current: currentPageDetail,
                }}
                scroll={{ x: 800 }}
              />
            </Col>
          </Row>
        </Card>
      </Layout.Content>

    </Layout>
  );
};

export default PickingList;
