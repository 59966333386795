export default {
  login: 'ĐĂNG NHẬP',
  password: 'Mật khẩu',
  slogan: 'Luôn luôn dõi theo và tư vấn hỗ trợ doanh nghiệp!',
  akaName: 'HỆ THỐNG QUẢN LÝ TỐI ƯU KHO ',
  akaDescription: 'Chuyển Đổi Số (DX)',
  placeholderPassword: 'Mật khẩu',
  messagePassword: 'Vui lòng điền mật khẩu!',
  placeholderUsername: 'Tên đăng nhập',
  messageUsername: 'Vui lòng điền Tên đăng nhập',
  loading: 'Đang tải',
  inbound: 'Nhập hàng',
  receipt: 'PO',
  temporaryInbound: 'Tạm Nhập',
  storing: 'Lưu trữ',
  inboundControl: 'Quản lý Nhập hàng',
  inventory: 'Kiểm kê',
  warehouseState: 'Trạng thái Kho',
  reallocation: 'Chuyển vị trí',
  productManagement: 'Quản lý Sản phẩm',
  outbound: 'Xuất hàng',
  pickingOrder: 'Đơn hàng',
  packaging: 'Đóng gói',
  shippingOutboundControl: 'Quản lý xuất kho',
  configuration: 'Tùy chỉnh',
  supplier: 'Nhà cung cấp',
  product: 'Sản phẩm',
  productCategory: 'Nhóm hàng',
  location: 'Vị trí',
  replenishment: 'Bổ sung Hàng',
  saleOrder: 'Đơn hàng',
  reporting: 'Báo cáo',
  auditOrder: 'Kiểm toán Đơn hàng',
  inventoryWarning: 'Cảnh báo Kiểm kê',
  performance: 'Năng suất',
  changePassword: 'Đổi Mật khẩu',
  logout: 'Đăng xuất',
  confirm: 'Xác nhận',
  confirmLogout: 'Bạn muốn đăng xuất?',
  warehouse: 'Nhà kho',
  user: 'Người dùng',
  admin: 'Admin',
  receiptId: 'ID Phiếu nhập kho',
  receiptCode: 'Mã Phiếu nhập kho',
  inboundKind: 'Loại Nhập kho',
  status: 'Trạng thái',
  receiptDate: 'Ngày tạo phiếu',
  receiveDate: 'Ngày Nhận',
  from: 'Từ',
  to: 'Tới',
  creator: 'Người tạo',
  keyword: 'Từ khóa',
  search: 'Tìm kiếm',
  receiptList: 'Danh sách Phiếu nhập kho',
  doYouWantToDeleteTheseItems: 'Bạn muốn xóa các mục này?',
  deleteReceiptsSuccessfully: 'Đã xóa Phiếu nhập kho thành công',
  receiptIdsHasBeenDeleted: 'Các ID Phiếu nhập kho đã bị xóa:',
  productCode: 'Mã lô sản phẩm',
  productName: 'Tên sản phẩm',
  expectedQuantity: 'Số lượng mong muốn',
  cost: 'Giá mua',
  salePrice: 'Giá bán sỉ',
  expireDate: 'Ngày hết hạn',
  createReceipt: 'Tạo Phiếu nhập kho',
  createReceiptSuccessfully: 'Tạo Phiếu nhập kho thành công',
  pleaseSelectAInboundKind: 'Vui lòng chọn loại Nhập hàng',
  messageReceiptDate: 'Vui lòng điền ngày Biên nhận',
  messageReceiveDate: 'Vui lòng điền ngày Nhận',
  productAdded: 'Sản phẩm đã được thêm vào',
  importCSV: 'Thêm Excel',
  pleaseSelecteAProduct: 'Vui lòng chọn sản phẩm',
  messageExpectedQuantity: 'Vui lòng điền Số lượng mong muốn',
  messageCost: 'Vui lòng điền Giá mua',
  totalQuantity: 'Tổng số lượng',
  zone: 'Zone',
  block: 'Block',
  shelf: 'Shelf',
  rack: 'Rack',
  confirmReceiptSuccessfully: 'Xác nhận Phiếu nhập kho thành công',
  receiptDetail: 'Chi tiết Phiếu nhập kho',
  productList: 'Danh sách Sản phẩm',
  updateLocationSuccessfully: 'Cập nhật vị trí thành công',
  updateLocation: 'Cập nhật vị trí',
  print: 'In',
  messageTotalQuantity: 'Vui lòng điền Tổng số lượng',
  importDate: 'Ngày nhập nội dung',
  report: 'Báo cáo',
  scanReturnProduct: 'Scan hàng trả về',
  reportErrorProduct: 'Báo cáo sản phẩm lỗi',
  reportSuccessfully: 'Báo cáo thành công',
  reportContent: 'Nội dung báo cáo',
  messageReportContent: 'Vui lòng nhập nội dung báo cáo',
  btnSubmit: 'Gửi đi',
  inputCode: 'Nhập mã',
  packageCode: 'Mã Đóng gói',
  locationCode: 'Mã Vị trí',
  completeScanReturnProductSuccessfully: 'Hoàn thành scan sản phẩm',
  scanReturnProductComplete: 'Hoàn thành scan sản phẩm trả về',
  scanMore: 'Scan thêm',
  scan: 'Scan',
  storingDate: 'Ngày lưu trữ',
  scanProduct: 'Scan sản phẩm',
  storingScanProduct: 'Scan sản phẩm lưu trữ',
  completeScanStoringSuccessfully: 'Scan sản phẩm lưu trữ thành công',
  storingScanProductComplete: 'Danh sách sản phẩm đã scan',
  inboundControlDate: 'Ngày nhập hàng',
  inboundControlScanProduct: 'Scan nhập hàng',
  completeInboundControlScanSuccessfully: 'Scan nhập hàng thành công',
  inboundControlScanProductComplete: 'Danh sách sản phẩm đã scan',
  username: 'Tên đăng nhập',
  name: 'Tên',
  role: 'Vai trò',
  users: 'Người dùng',
  requiredUsername: 'Tên đăng nhập bắt buộc có',
  requiredPassword: 'Mật khẩu bắt buộc có',
  requiredName: 'Tên bắt buộc có',
  requiredRole: 'Vai trò bắt buộc có',
  requiredWarehouse: 'Nhà kho bắt buộc có',
  warehouseCode: 'Mã Nhà kho',
  warehouseName: 'Tên Nhà kho',
  address: 'Địa chỉ',
  warehouses: 'Nhà kho',
  acreage: 'Diện tích',
  btnCancel: 'Hủy',
  btnComplete: 'Hoàn tất',
  btnUpdate: 'Cập nhật',
  btnCreate: 'Tạo mới',
  btnSearch: 'Tìm kiếm',
  btnAdd: 'Thêm',
  btnEdit: 'Sửa',
  btnConfirm: 'Xác nhận',
  btnDelete: 'Xóa',
  btnSave: 'Lưu',
  confirmQuestion: 'Nội dung đã được sửa đổi, các thay đổi sẽ bị loại bỏ. Bạn có muốn tiếp tục?',
  confirmContent: 'Nếu bạn hủy, thông tin vừa sửa sẽ bị hủy bỏ.',
  yes: 'Có',
  addZone: 'Thêm Zone',
  addShelf: 'Thêm Shelf',
  addBlock: 'Thêm Block',
  zoneDetail: 'Chi tiết Zone',
  zoneCreate: 'Tạo Zone',
  zoneName: 'Tên Zone',
  zoneAreDeletedSuccessfully: 'Zone đã được xóa thành công',
  blockAreDeletedSuccessfully: 'Block đã được xóa thành công',
  shelfAreDeletedSuccessfully: 'Shelf đã được xóa thành công',
  areYouSureToDeleteThisZone: 'Bạn có chắc muốn xóa zone?',
  areYouSureToDeleteThisBlock: 'Bạn có chắc muốn xóa block?',
  areYouSureToDeleteThisShelf: 'Bạn có chắc muốn xóa shelf?',
  placeholderName: 'Vui lòng điền Tên',
  placeholderSelectMethod: 'Vui lòng chọn Phương thức xuất hàng',
  requiredCategoryName: 'Tên nhóm hàng bắt buộc có',
  requiredMethod: 'Phương thức xuất hàng bắt buộc có',
  placeholderSelectProductCate: 'Vui lòng chọn Nhóm hàng',
  placeholderAddress: 'Vui lòng điền Địa chỉ',
  placeholderPhoneNumber: 'Vui lòng điền Số điện thoại',
  requiredCategory: 'Nhóm hàng bắt buộc có',
  requiredSupplierName: 'Tên Nhà cung cấp bắt buộc có',
  requiredEmail: 'Email bắt buộc có',
  requiredAddress: 'Địa chỉ bắt buộc có',
  requiredPhoneNumber: 'Số điện thoại bắt buộc có',
  placeholderSelectSupplier: 'Vui lòng chọn Nhà cung cấp',
  placeholderCapacity: 'Vui lòng điền Sức chứa',
  requiredImage: 'Hình ảnh bắt buộc có',
  requiredProductName: 'Tên sản phẩm bắt buộc có',
  requiredSupplier: 'Nhà cung cấp bắt buộc có',
  requiredCapacity: 'Sức chứa bắt buộc có',
  requiredStoreTime: 'Thời gian lưu kho bắt buộc có',
  placeholderMin: 'Vui lòng điền Giá trị bé nhất',
  placeHolderMax: 'Vui lòng điền Giá trị lớn nhất',
  requiredMin: 'Giá trị bé nhất bắt buộc có',
  requiredMax: 'Giá trị lớn nhất bắt buộc có',
  placeholderSelectProduct: 'Vui lòng chọn Sản phẩm',
  requiredProduct: 'Sản phẩm bắt buộc có',
  requiredZoneName: 'Tên Zone bắt buộc có',
  requiredBlockName: 'Tên Block bắt buộc có',
  placeholderTotalshelf: 'Vui lòng điền Tổng số shelf',
  requiredTotalshelf: 'Tổng số Shelf bắt buộc có',
  requiredShelfName: 'Tên Shelf bắt buộc có',
  placeholderTotalRack: 'Vui lòng điền Tổng số rack',
  requiredTotalRack: 'Tổng số Rack bắt buộc có',
  placeholderLevelPercent: 'Vui lòng điền mức %',
  requiredLevelPercent: 'Mức % bắt buộc có',
  placeholderQuantity: 'Điền số lượng',
  requiredQuantity: 'Số lượng bắt buộc có',
  capacity: 'Sức chứa',
  success: 'Thành công',
  blockDetail: 'Chi tiết Block',
  blockCreate: 'Tạo Block',
  blockName: 'Tên Block',
  totalShelf: 'Tổng số Shelf',
  updateShelf: 'Cập nhật Shelf',
  createShelf: 'Tạo Shelf',
  update: 'Cập nhật',
  complete: 'Hoàn tất',
  shelfName: 'Tên Shelf',
  capacityRack: 'Sức chứa của Rack',
  levelPercent: 'Mức %',
  medium: 'trung bình',
  high: 'cao',
  code: 'Mã',
  createSuccessfully: 'Tạo thành công',
  updateSuccessfully: 'Cập nhật thành công',
  deleteSuccessfully: 'Xóa thành công',
  idsHasBeenDeleted: 'ID đã bị xóa:',
  email: 'Email',
  orderCode: 'Mã Đặt hàng',
  userName: 'Tên đăng nhập',
  orderDate: 'Ngày đặt hàng',
  orderQuantity: 'Số lượng Đặt hàng',
  pickingquantity: 'Số lượng đã lấy',
  placeholderSelectOrderCode: 'Chọn mã Đặt hàng',
  titlePicking: 'Thông tin Đặt hàng và Lấy hàng',
  suggestLocation: 'Gợi ý vị trí',
  quantity: 'Số lượng',
  createPicking: 'Tạo danh sách lấy hàng',
  labelBooking: 'Đặt hàng',
  placeholderSelectOrder: 'Chọn mã Đặt hàng',
  okText: 'Đồng ý',
  cancelText: 'Hủy',
  titleModalBooking: 'Chọn Đặt hàng',
  scanCode: 'Scan mã',
  confirmPickupList: 'Xác nhận Danh sách lấy hàng',
  titleModalPslitProduct: 'Phân chia Sản phẩm',
  placeholderSelectPackageCode: 'Chọn Mã đóng gói',
  btnDone: 'Xong',
  btnClose: 'Đóng',
  titleModalSuccessSuggestLocation: 'Vị trí gợi ý được in thành công',
  pickupList: 'Danh sách Lấy hàng',
  generatePackageCode: 'Phát hành Mã đóng gói',
  orderNumber: 'Số Đặt hàng',
  productOrderCode: 'Mã sản phẩm đặt hàng',
  pickingList: 'Danh sách lấy hàng',
  packagingCode: 'Mã đóng gói',
  tempOutLot: 'Khu vực tạm xuất',
  orderandpackagingInfo: 'Thông tin Đặt hàng và Đóng gói',
  packagingDetail: 'Chi tiết đóng gói',
  orderandshippingInfo: 'Thông tin Đặt hàng và Ship hàng',
  confirmPackageList: 'Xác nhận Danh sách đóng gói',
  outQuantity: 'Số lượng đã hết',
  startDate: 'Ngày bắt đầu',
  endDate: 'Ngày kết thúc',
  orderInfo: 'Thông tin Đặt hàng',
  inStock: 'Trong kho',
  expirationDate: 'Ngày hết hạn',
  storageExpirationDate: 'Ngày hết hạn lưu kho',
  exportCSV: 'Xuất Excel',
  agingStockAlert: 'Cảnh báo Quá ngày lưu kho',
  dotRestock: 'Vui lòng bổ sung sản phẩm cho đợt hàng tiếp theo',
  productDetail: 'Chi tiết Sản phẩm',
  total: 'Tổng số',
  inventorySummary: 'Tổng kết Lưu kho',
  inboundsummary: 'Tổng kết Nhập hàng',
  productSummary: 'Tổng kết Xuất hàng',
  action: 'Hành động',
  warehouseBlockDetail: 'Chi tiết Block trong kho',
  requiredReportContent: 'Nội dung báo cáo bắt buộc có',
  editLocation: 'Thay đổi vị trí',
  dotEditLocation: 'Vui lòng chọn sản phẩm bạn muốn thay đổi vị trí',
  onlyEditLocation: 'Chỉ được phép thay đổi 1 dòng mỗi thời điểm',
  pendingState: 'Trạng thái Chờ',
  inboundState: 'Trạng thái Nhập hàng',
  lostState: 'Trạng thái Mất',
  updateProduct: 'Cập nhật sản phẩm',
  requiredZone: 'Zone bắt buộc có',
  requiredBlock: 'Block bắt buộc có',
  requiredShelf: 'Shelf bắt buộc có',
  requiredRack: 'Rack bắt buộc có',
  requiredExpirationDate: 'Ngày hết hạn bắt buộc có',
  requiredTotalQauntity: 'Tổng số lượng bắt buộc có',
  requiredCost: 'Giá mua bắt buộc có',
  requiredSalePrice: 'Giá bán bắt buộc có',
  requiredStatus: 'Trạng thái bắt buộc có',
  requiredLostDate: 'Ngày mất bắt buộc có',

  totalRackbetween: 'Tổng số rack (từ 1 ~ 100)',
  backtoProductList: 'Quay về Danh sách Sản phẩm',
  productInfo: 'Thông tin sản phẩm',
  storageTime: 'Thời gian lưu kho còn lại (ngày)',
  productImage: 'Hình ảnh Sản phẩm',
  imageExtra: 'Bạn có thể tải lên hình ảnh dung lượng tối đa 5MB',
  upload: 'Tải lên',
  methodExport: 'Chiến lược xuất hàng',
  createDate: 'Ngày tạo',
  createProduct: 'Tạo sản phẩm',
  productCategoryName: 'Tên Nhóm hàng',
  productCategoryCode: 'Mã Nhóm hàng',
  productCategoryList: 'Danh sách Nhóm hàng',
  updateProductCategoty: 'Cập nhật Nhóm hàng',
  createProductCategoty: 'Tạo Nhóm hàng',
  minQuantity: 'Số lượng tối thiểu',
  maxQuantity: 'Số lượng tối đa',
  notification: 'Thông báo',
  onHand: 'Đang có',
  toOrder: 'Đặt hàng',
  replenishmentList: 'Danh sách Bổ sung hàng',
  createReplenishment: 'Tạo Bổ sung hàng',
  updateReplenishment: 'Cập nhật Bổ sung hàng',
  backtoSupplierList: 'Quay về Danh sách Nhà cung cấp',
  supplierInfo: 'Thông tin Nhà cung cấp',
  phone: 'Số điện thoại',
  cooperationDate: 'Ngày hợp tác',
  supplierName: 'Tên Nhà cung cấp',
  requiredCooperationDate: 'Ngày hợp tác bắt buộc có',
  supplierCode: 'Mã Nhà cung cấp',
  supplierList: 'Danh sách Nhà cung cấp',
  createSupplier: 'Tạo Nhà cung cấp',
  updateSupplier: 'Cập nhật Nhà cung cấp',
  createSaleOrder: 'Tạo Đơn hàng',
  addProduct: 'Thêm Sản phẩm',
  editProduct: 'Sửa Sản phẩm',
  saleOrderDetail: 'Chi tiết Đơn hàng',
  orderList: 'Danh sách Đặt hàng',
  reportDate: 'Ngày báo cáo',
  firstName: 'Họ',
  lastName: 'Tên',
  lostDate: 'Ngày mất',
  phoneLengthMessage: 'Số điện thoại không được dài hơn 11 ký tự',
  phoneMessage: 'Số điện thoại phải là số',
  full: 'Đầy',
  empty: 'Còn trống',
  Medium: 'Trung bình',
  resetPassword: 'Khôi phục mật khẩu',
  passwordNew: 'Mật khẩu mới',
  currentPassword: 'Mật khẩu hiện tại',
  passwordConfirm: 'Xác nhận mật khẩu',
  zoneNameExist: 'Tên zone đã tồn tại',
  blockNameExist: 'Tên block đã tồn tại',
  shelfNameExist: 'Tên shelf đã tồn tại',
  no: 'Không',
  subTitleInboundControl: 'Vui lòng quét vị trí trước khi đặt sản phẩm lên kệ',
  subTitleOutboundCreatePickUpNotify: 'Vui lòng chọn đơn đặt hàng',
  subTitleStoringNotify: 'Vui lòng quét mã QR của sản phẩm trước khi vận chuyển',
  subTitleInboundTemporaryNotify: 'Vui lòng quét mã QR của sản phẩm trước khi vận chuyển',
  passwordRegex: 'Mật khẩu phải có tối thiểu 8 ký tự',
  updateImageLess: 'Vui lòng tải ảnh nhỏ hơn 5MB',
  creatorName: 'Người tạo',
  invalidCode: 'Mã không tồn tại',
  tryAgain: 'Vui lòng thử lại',
  noRecordsFound: 'Không có kết quả tìm kiếm!',
  emailRequirePlaceholder: 'Vui lòng điền email',
  messeageExpiration: 'Ngày hết hạn là bắt buộc',
  unlockAccount: 'Mở khóa tài khoản thành công',
  January: 'Tháng 1',
  February: 'Tháng 2',
  March: 'Tháng 3',
  April: 'Tháng 4',
  May: 'Tháng 5',
  June: 'Tháng 6',
  July: 'Tháng 7',
  August: 'Tháng 8',
  September: 'Tháng 9',
  October: 'Tháng 10',
  November: 'Tháng 11',
  December: 'Tháng 12',
  orderStatus: 'Trạng thái đặt hàng',
  packageStatus: 'Trạng thái đóng gói',
  splitproduct: 'Tách sản phẩm',
  splitProductList: 'Danh sách tách sản phẩm',
  defaultCode: 'Mã sản phẩm',
  requiredDefaultCode: 'Mã mặc định là bắt buộc có',
  placeHolderDefaultCode: 'Vui lòng nhập mã mặc định',
  placeholderSalePrice: 'Vui lòng nhập giá bán',
  retailPrice: 'Giá bán lẻ',
  placeholderRetailPrice: 'Vui lòng nhập giá bán lẻ',
  lot: 'Số lượng lô',
  requiredLot: 'Số lượng lô là bắt buộc có',
  VAT: 'Thuế (%)',
  Divison: 'Khối hàng',
  Category: 'Ngành hàng',
  divisonCode: 'Mã khối hàng',
  divisonName: 'Tên khối hàng',
  createDivison: 'Tạo khối hàng',
  updateDivison: 'Cập nhật khối hàng',
  placeholderDivisonName: 'Vui lòng nhập tên khối hàng',
  requiredDivisonName: 'Tên khối hàng là bắt buộc',
  categoryCode: 'Mã ngành hàng',
  categoryName: 'Tên ngành hàng',
  createCategory: 'Tạo ngành hàng',
  updateCategory: 'Cập nhật ngành hàng',
  placeholderCategoryName: 'Vui lòng nhập tên ngành hàng',
  requiredParentCategoryName: 'Tên ngành hàng là bắt buộc',
  requiredDivison: 'Khối hàng là bắt buộc',
  requiredParentCategory: 'Ngành hàng là bắt buộc',
  categoryList: 'Danh sách ngành hành',
  divisonList: 'Danh sách khối hàng',
  DVT: 'Đơn vị tính',
  isActive: 'Kích hoạt',
  contractNumber: 'Số hợp đồng',
  packingRule: 'Quy cách đóng gói',
  NEW: 'MỚI',
  ERROR: 'LỖI',
  TEMPORARY: 'TẠM NHẬP',
  STORED: 'TRONG KHO',
  MOVING: 'DI CHUYỂN',
  TEMPORARY_OUT: 'XUẤT',
  LOST: 'MẤT',
  REALLOCATE: 'ĐỔI LẠI',
  ENABLE: 'CHO PHÉP',
  DISABLE: 'VÔ HIỆU HÓA',
  requestReceipt: 'Tạo PR',
  note: 'Ghi chú',
  itemCode: 'Mã item',
  length: 'Dài',
  width: 'Rộng',
  height: 'Cao',
  RECEIVE: 'NHẬN',
  dowloadTemplate: 'Tải mẫu',
  importSuccess: 'Tải lên thành công',
  exportPDF: 'Xuất PDF',
  discount: 'Chiết khấu',
  isResources: 'Nguồn hàng',
  vansCode: 'Mã xe',
  createPO: 'Tạo PO',
  taxCode: 'Mã số thuế',
  requiredPackingRule: 'Quy cách đóng gói là bắt buộc',
  CANCELLED: 'HỦY',
  PICKING: 'ĐÃ LẤY',
  PACKAGED: 'ĐÓNG GÓI',
  deliveryTime: 'Thời gian giao hàng',
  messeagePicking: 'Đơn hàng đang được chọn bởi 1 người dùng khác',
};
